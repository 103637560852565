import { useAuth0 } from '@auth0/auth0-react';
import { authExchange } from '@urql/exchange-auth';
import { useAtom } from 'jotai';
import { jwtDecode } from 'jwt-decode';
import { useCallback } from 'react';
import { cacheExchange, createClient, fetchExchange } from 'urql';
import { urqlClient } from '~/global-state/urqlClient';

import type { Client } from 'urql';

export function useUrql() {
  const [client, setClient] = useAtom<Client | undefined>(urqlClient);
  const { getAccessTokenSilently, logout } = useAuth0();

  const initUrqlClient = useCallback(() => {
    const auth0Exchange = authExchange(async (utils) => {
      let token = await getAccessTokenSilently();

      return {
        addAuthToOperation(operation) {
          if (!token) {
            return operation;
          }
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        },
        async refreshAuth() {
          token = await getAccessTokenSilently();
          if (!token) {
            logout();
          }
        },
        willAuthError() {
          if (!token) {
            return true;
          }

          const decodedToken = jwtDecode<{ [key: string]: number }>(token);

          if ('exp' in decodedToken) {
            const now = Date.now() / 1000;
            return now >= decodedToken.exp;
          }

          return false;
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
        },
      };
    });

    const newClient = createClient({
      url: `${BACKEND_HOST}${API_PATH}/graphql`,
      exchanges: [cacheExchange, auth0Exchange, fetchExchange],
    });

    setClient(newClient);
  }, [setClient, getAccessTokenSilently, logout]);

  return { client, initUrqlClient, clearCache: initUrqlClient };
}
